<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title"> {{ $t('li_step.crop_wise_report') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(loadData)" @reset.prevent="reset" >
                    <b-row>
                      <b-col lg="4" sm="4">
                        <ValidationProvider name="Crop" vid="crop_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="crop_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{ $t('common_config.crop_name') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="search.crop_id"
                                    :options="cropList"
                                    id="crop_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" md="4" sm="4">
                        <b-form-group
                          :label="$t('globalTrans.from_date')"
                          label-for="from_date"
                        >
                          <b-form-input
                            id="datepicker"
                            v-model="search.from_date"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col lg="4" md="4" sm="4">
                          <b-form-group
                            :label="$t('globalTrans.to_date')"
                            label-for="to_date"
                          >
                          <b-form-input
                            id="datepicker"
                            v-model="search.to_date"
                            ></b-form-input>
                          </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.search') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row v-if="showData">
          <b-col md="12">
            <b-overlay :show="loading">
              <iq-card>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('li_step.crop_wise_report') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <!-- <b-button class="btn_add_new mr-2" @click="pdfExport">
                            <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                          </b-button> -->
                        </template>
                        <template v-slot:body>
                          <b-overlay>
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                  <b-row>
                                    <b-col>
                                      <list-report-head :baseUrl="licenseRegistrationServiceBaseUrl" :url="'/configuration/report-heading/detail'" org-id="1">
                                        {{ $t('li_step.crop_wise_report') }}
                                      </list-report-head>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col>
                                      <b-row class="mb-3">
                                        <b-col md="4" class="text-left">
                                          {{ 'ফসলের নাম' }}: <strong>{{ search.crop_id ? getOrganizationName(search.crop_id) : $t('globalTrans.all') }}</strong>
                                        </b-col>
                                        <b-col md="4">
                                          <slot v-if="search.from_date">
                                            {{ $t('globalTrans.from_date') }}: <strong>{{ search.from_date | dateFormat }}</strong>
                                          </slot>
                                          <slot v-else>
                                            {{ $t('globalTrans.from_date') }}: <strong>{{ $t('globalTrans.all') }}</strong>
                                          </slot>
                                        </b-col>
                                        <b-col md="4">
                                          <slot v-if="search.to_date">
                                            {{ $t('globalTrans.to_date') }}: <strong>{{ search.to_date | dateFormat }}</strong>
                                          </slot>
                                          <slot v-else>
                                            {{ $t('globalTrans.to_date') }}: <strong>{{ $t('globalTrans.all') }}</strong>
                                          </slot>
                                        </b-col>
                                        <!-- <b-col md="6" class="text-left">
                                          {{ $t('org_pro.service_namel') }}: <strong>{{ search.service_id ? getServiceName(search.service_id) : $t('globalTrans.all') }}</strong>
                                        </b-col> -->
                                      </b-row>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col md="6">
                                      <b>{{ $t('boro_import.import_seed') }} </b>
                                      <br/>
                                      <b-table-simple hover small responsive bordered style="margin-top: 10px">
                                        <b-thead>
                                          <b-tr>
                                            <b-th>{{ $t('boro_import.variety_name') }}</b-th>
                                            <b-th>{{ $t('boro_import.import_country') }}</b-th>
                                            <b-th>{{ $t('boro_import.import_company') }}</b-th>
                                            <b-th>{{ $t('boro_import.import_qty') }}</b-th>
                                          </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                          <template v-if="importData.length">
                                            <template v-for="data in importData">
                                              <b-tr v-for="(dataIt, index1) in JSON.parse(data.add_more_8371)" :key="index1">
                                                <b-td>{{ dataIt['Crop Variety'] }}</b-td>
                                                <b-td>{{ dataIt['Exporting Country Name'] }}</b-td>
                                                <b-td>{{ dataIt['Seed Exporting Company Name(Bn)'] }}</b-td>
                                                <b-td>{{ dataIt['Imported Quantity in this Fiscal year till Date (MT)'] }}</b-td>
                                                <!-- <b-td>
                                                  <pre>{{dataIt}}</pre>
                                                </b-td> -->
                                              </b-tr>
                                            </template>
                                          </template>
                                          <b-tr v-else>
                                            <b-td colspan="4" class="text-center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                          </b-tr>
                                        </b-tbody>
                                      </b-table-simple>
                                    </b-col>
                                    <b-col md="6">
                                      <b>{{ $t('boro_import.export_seed') }} </b>
                                      <br/>
                                      <b-table-simple hover small responsive bordered style="margin-top: 10px">
                                        <b-thead>
                                          <b-tr>
                                            <b-th>{{ $t('boro_import.variety_name') }}</b-th>
                                            <b-th>{{ $t('boro_import.export_country') }}</b-th>
                                            <b-th>{{ $t('boro_import.export_company') }}</b-th>
                                            <b-th>{{ $t('boro_import.export_qty') }}</b-th>
                                          </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                          <template v-if="exportData.length">
                                            <b-tr v-for="(data, index) in exportData" :key="index">
                                              <b-td>{{ data.variety_name }}</b-td>
                                              <b-td>{{ data.export_country_name }}</b-td>
                                              <b-td>{{ data.export_company_name }}</b-td>
                                              <b-td>{{ data.export_quantity }}</b-td>
                                            </b-tr>
                                          </template>
                                          <b-tr v-else>
                                            <b-td colspan="4" class="text-center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                          </b-tr>
                                        </b-tbody>
                                      </b-table-simple>
                                    </b-col>
                                  </b-row>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-overlay>
          </b-col>
          <!-- <pre>{{ importData }}</pre> -->
          <!-- <pre>{{ exportData }}</pre> -->
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { importExportReport } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHeadLrcpnOn.vue'
import ExportPdf from './export_pdf'
import flatpickr from 'flatpickr'

export default {
  props: [],
  components: {
    ValidationObserver,
    ValidationProvider,
    ListReportHead
  },
  data () {
    return {
      search: {
        crop_id: 0
      },
      licenseRegistrationServiceBaseUrl: licenseRegistrationServiceBaseUrl,
      listData: [],
      serviceNamesList: [],
      importData: [],
      exportData: [],
      totalInfo: {},
      excelColumnBn: {},
      excelColumn: {},
      showData: false
    }
  },
  computed: {
    loading () {
        return this.$store.state.commonObj.loading
    },
    cropList () {
      return this.$store.state.licenseRegistration.commonObj.cropList
    }
  },
  watch: {
    'search.org_id': function (newVal) {
      if (newVal) {
        this.serviceNamesList = this.getServiceList(newVal)
      } else {
        this.serviceNamesList = []
      }
    }
  },
  created () {
    if (this.authOrgId) {
      this.search.org_id = this.authOrgId
    }
  },
  mounted () {
    core.index()
    flatpickr('#datepicker', {})
  },
  methods: {
    pdfExport () {
      const reportTitle = this.$t('li_step.payment_history_report')
      ExportPdf.exportPdfDetails(licenseRegistrationServiceBaseUrl, '/configuration/report-heading/detail', this.search.org_id, reportTitle, this)
    },
    async loadData () {
      this.showData = true
      this.listData = []
      this.totalInfo = {}
      this.$store.commit('mutateCommonProperties', {
          loading: true
      })
      const result = await RestApi.getData(licenseRegistrationServiceBaseUrl, importExportReport, this.search)
      if (result.success) {
        this.$store.commit('mutateCommonProperties', {
          loading: false
        })
        this.exportData = result.data.export
        this.importData = result.data.import
      } else {
        this.exportData = []
        this.importData = []
        this.$store.commit('mutateCommonProperties', {
          loading: false
        })
      }
    },
    getServiceList (orgId) {
      const tmpService = this.$store.state.licenseRegistration.commonObj.serviceNamesList.filter(item => (item.org_id === orgId))
      return tmpService.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'bn' ? item.text_bn : item.text_en })
      })
    },
    getOrganizationName (id) {
      const obj = this.$store.state.licenseRegistration.commonObj.cropList.find(item => item.value === id)
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    }
  }
}
</script>
<style >
.table-bordered th{
border: 1px solid #000 !important;
}
</style>
